<template>
  <b-row>
    <b-col cols="12">
      <!-- Default Colors -->
      <b-card
        title="Outil d'export comptable"
        class="background-color"
      >

        <b-row>
          <b-col md="4">
            <b-form-group>
              <h5>Date d'export :</h5>
              <flat-pickr
                v-model="rangeDate"
                placeholder="Choisissez une date"
                class="form-control"
                :config="{ mode: 'range'}"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <h5>Pays :</h5>
              <v-select
                v-model="country"
                placeholder="Choisissez un pays"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="countryOptions"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            class="text-center"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="dispatch"
              >
                Générer export compta
              </b-button>
            </b-form-group>
          </b-col>
          <b-col
            class="text-center"
            md="2"
          >
            <b-form-group>
              <b-button
                variant="primary"
                :disabled="loading"
                @click="avoir"
              >
                Générer les avoirs
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
    <!--/ Gradient color start -->
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    flatPickr,
    vSelect,
    BFormGroup,
    BButton,
    BRow,
    BCol,
  },
  data() {
    return {
      rangeDate: null,
      loading: false,
      country: 'FR',
      countryOptions: ['ES', 'FR', 'DE', 'DE_OLD', 'IT', 'PT', 'NL', 'EN', 'BB', 'DK', 'UK'],
    }
  },
  methods: {
    async avoir() {
      try {
        this.loading = true
        if (this.selected === '') {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner un logisticien.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (!this.rangeDate) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner une date de dispatch.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        await this.$http({
          url: '/admin/avoir-compta',
          method: 'POST',
          data: {
            rangeDate: this.rangeDate,
            country: this.country,
          },
        })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous allez recevoir l\'export par email.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        return true
      } catch (err) {
        return err
      } finally {
        this.loading = false
      }
    },
    async dispatch() {
      try {
        this.loading = true
        if (this.selected === '') {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner un logisticien.',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        if (!this.rangeDate) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Vous devez sélectionner une date d\'export',
              icon: 'EditIcon',
              variant: 'warning',
            },
          })
          return false
        }
        await this.$http({
          url: '/admin/export-compta',
          method: 'POST',
          data: {
            rangeDate: this.rangeDate,
            country: this.country,
          },
        })
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous allez recevoir l\'export par email.',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        return true
      } catch (err) {
        return err
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style lang="scss">
.colors-list {
  li {
    padding: 0.4rem;
    svg {
      margin-right: 0.25rem;
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
